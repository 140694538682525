import { Cloudinary } from '@cloudinary/url-gen'
const QUALITY = 20
export const cloudinary = new Cloudinary({
  cloud: {
    cloudName: 'daarbdvsw',
  },
})

export const idToCloudinaryUrl = (id?: string | null | undefined) => {
  if (!id) {
    return
  }

  if (id.includes('http')) {
    return id
  }

  const src = cloudinary.image(id).quality(QUALITY).format('webp').toURL()
  return src
}
